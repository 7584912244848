/**
 * KNVB eLearning - Bijscholing
 *
 * @author Sebastian Kersten (@supertaboo)
 */

'use strict';



module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {



    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {

    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    select: function(nCourseMaterialId, bChangeCourseMaterialBijscholing)
    {
        // verify with user
        if (bChangeCourseMaterialBijscholing)
        {
            if (confirm('Weet je zeker dat je de reeds gestarte bijscholingstoets wilt overschijven?'))
            {
                if (confirm('Weet je het echt zeker? Deze actie kan niet worden teruggedraaid!'))
                {
                    this._selectCourseMaterial(nCourseMaterialId);
                }
            }
        }
        else
        {
            this._selectCourseMaterial(nCourseMaterialId);
        }
    },

    _selectCourseMaterial: function(nCourseMaterialId)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // scroll to top
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                    // show course options
                    window.open('/', '_self');
                }
                else
                {
                    classRoot._slideContainer.innerHTML = 'Er heeft zich een probleem voorgedaan met het selecteren van een cursus. Neem a.u.b. contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.';
                }
            }
        };

        // prepare
        request.open('get', '/bijscholing/select/' + nCourseMaterialId);

        // send
        request.send();
    },


    selectDate: function(sDate)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // scroll to top
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                    // show course options
                    window.open(response.nextPage, '_self');
                }
                else
                {
                    classRoot._slideContainer.innerHTML = 'Er heeft zich een probleem voorgedaan met het selecteren van een cursus. Neem a.u.b. contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.';
                }
            }
        };

        // prepare
        request.open('get', '/bijscholing/selectdate/' + sDate);

        // send
        request.send();
    },

    submit: function()
    {
        // 1. init
        var request = new XMLHttpRequest();

        // 2. setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // toggle
                    document.getElementById('EndresultBijscholingSlide-inzenden-button').style.display = 'none';
                    document.getElementById('EndresultBijscholingSlide-inzenden-geslaagd').style.display = 'block';
                }
                else
                {
                    alert('Er heeft zich een probleem voorgedaan met het inzenden van je bijscholingstoets (Vrees niet! Je resultaten zijn gewoon opgeslagen). Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // 3. prepare
        request.open('get', '/bijscholing/submit');

        // 4. send
        request.send();
    },

}
