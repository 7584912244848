/**
 * KNVB eLearning - KNVB
 *
 * @author Sebastian Kersten (@supertaboo)
 */

'use strict';


// Mimoto classes
var Schedule = require('./Schedule');
var Student = require('./Student');
var Bijscholing = require('./Bijscholing');
var Course = require('./Course');
var UI = require('./UI');


module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {

    // interface
    schedule: null,
    ui: null,

    // users
    student: null,

    // course
    course: null,
    bijscholing: null,



    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {

    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    startup: function()
    {
        // 1. setup schedule
        this.schedule = new Schedule();
        this.ui = new UI();

        // 2. setup student
        this.student = new Student();

        // 3. setup course
        this.course = new Course();
        this.bijscholing = new Bijscholing();
    },

    confirmRemoval: function()
    {
        return confirm('Weet je zeker dat je dit item wilt verwijderen?');
    },

    confirmClassTypeChangeToBijscholing: function()
    {
        return confirm('Weet je zeker dat je van deze klas een bijscholingsklas wilt maken?');
    },

    confirmClassTypeChangeToRegular: function()
    {
        return confirm('Weet je zeker dat je van deze klas een reguliere klas wilt maken?');
    },
    
}
