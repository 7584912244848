/**
 * KNVB eLearning - Schedule
 *
 * @author Sebastian Kersten (@supertaboo)
 * @author Heleen Emanuel (@supertaboo)
 */

'use strict';
import {CSSPlugin, TweenLite} from "gsap";
import Draggable from "gsap/Draggable";


var Tippy = require('tippy.js');


module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {


    // data
    _sRole: null,
    _aOptions: [],
    _nCurrentStep: null,
    _nCourseId: null,
    _bDontCompare: false,

    // buttons
    _elNextButton: null,
    _elCompareButton: null,

    // interface
    _aTooltips: [],


    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {
        // 1. register
        this._elNextButton = document.getElementById('button_next');
        this._elCompareButton = document.getElementById('button_compare');
    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    setup: function(sRole, aOptions, nCurrentStep, nCourseId, bDontCompare)
    {
        // store
        this._sRole = sRole;
        this._aOptions = aOptions;
        this._nCurrentStep = nCurrentStep;
        this._nCourseId = nCourseId;
        this._bDontCompare = bDontCompare;

        // setup menu
        this.setupMenu();

        // setup footer
        this._setupFooter();

        // focus trainig days
        this._surpressDaysOffOnGrid();
        this._styleMatchDaysOnGrid();

        // setup grid
        this._resizeGrid();

        // setup sticky day header
        this._setupStickyDayHeader();
    },

    setupMenu: function()
    {
        // 1. search
        var domScheduleOptions = document.getElementById('schedule_options');

        // 2. validate
        if (!domScheduleOptions || !this._aOptions) return;

        // 3. cleanup
        while (domScheduleOptions.firstChild) domScheduleOptions.removeChild(domScheduleOptions.firstChild);

        // 4. create
        var nOptionCount = this._aOptions.length;
        for (var nOptionIndex = 0; nOptionIndex < nOptionCount; nOptionIndex++)
        {
            // 4a. load
            var scheduleOption = document.getElementById('templates').querySelector('[data-knvb-schedule-option]').cloneNode(true);

            // 4b. setup
            scheduleOption.setAttribute('data-knvb-schedule-option-id', this._aOptions[nOptionIndex].id);
            scheduleOption.setAttribute('data-knvb-schedule-option-group', this._aOptions[nOptionIndex].group);

            // 4c. add label
            scheduleOption.querySelector('.ScheduleOption-label').innerText = (this._aOptions[nOptionIndex].shortValue) ? this._aOptions[nOptionIndex].shortValue : this._aOptions[nOptionIndex].value;
            scheduleOption.setAttribute('title', this._aOptions[nOptionIndex].label);

            // 4d. add value
            scheduleOption.querySelector('.ScheduleOption-label').setAttribute('data-value', this._aOptions[nOptionIndex].value);

            // 4e. inject layer option into dom
            domScheduleOptions.appendChild(scheduleOption);


            // setup
            var tip = Tippy(scheduleOption,
                {
                    theme: 'knvb',
                    flipDuration: 0,
                    popperOptions: {
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }
                    }
                });

            // compose
            var tooltip = {
                tip: tip,
                popper: tip.getPopperElement(scheduleOption)
            };

            // store
            this._aTooltips.push(tooltip);
        }


        this._scheduleOptions = document.querySelectorAll('[data-knvb-schedule-option]');
        this._aWeeks = document.querySelectorAll('[data-knvb-schedule-week]');

        if (this._nCurrentStep == 2)
        {
            this._dropContainers = document.querySelectorAll('[data-knvb-schedule-day], [data-knvb-schedule-week-type]');
        }
        else if (this._nCurrentStep == 6)
        {
            this._dropContainers = document.querySelectorAll('[data-knvb-day-option-group]');
        }
        else
        {
            this._dropContainers = document.querySelectorAll('[data-knvb-schedule-day]');
        }


        var nScheduleOptionCount = this._scheduleOptions.length;
        for (var nScheduleOptionIndex = 0; nScheduleOptionIndex < nScheduleOptionCount; nScheduleOptionIndex++)
        {
            this._createDraggableElement(this._scheduleOptions[nScheduleOptionIndex]);
        }
    },

    _setupFooter: function()
    {
        // 1. register
        var classRoot = this;

        // 2. validate
        if (!this._elCompareButton) return;

        // 3. hide
        if (this._elCompareButton && this._bDontCompare)
        {
            this._elCompareButton.style.display = 'none';
            return;
        }

        // 4. connect
        this._elCompareButton.addEventListener('click', function()
            {
                classRoot.compare();
            },
            false
        );

        // 5. toggle visibility
        if (this._checkInput()) { this._showNextButton(); } else { this._hideNextButton(); }
    },

    _showNextButton: function()
    {
        this._elNextButton.removeAttribute('style');
    },

    _hideNextButton: function()
    {
        this._elNextButton.style.display = 'none';
    },

    _checkInput: function()
    {
        // 1. init
        var nWeeksFilledIn = 0;
        var nMinWeeksFilledIn = 3;

        // 2. collect
        var aWeeks = document.querySelectorAll('[data-knvb-schedule-week]');

        // 3. check all weeks
        var nWeekCount = aWeeks.length;
        for (var nWeekIndex = 0; nWeekIndex < nWeekCount; nWeekIndex++)
        {
            // register
            var elWeek = aWeeks[nWeekIndex];

            if (this._nCurrentStep == 2)
            {
                // collect
                var elContainer = elWeek.querySelector('[data-knvb-schedule-week-type]');

                // verify
                if (elContainer.querySelectorAll('[data-knvb-week-option]').length > 0) nWeeksFilledIn++;
            }
            else
            {
                if (this._nCurrentStep == 6)
                {
                    // collect
                    var aContainers = elWeek.querySelectorAll('[data-knvb-day-option-group-container]');
                }
                else
                {
                    // collect
                    var aContainers = elWeek.querySelectorAll('[data-knvb-schedule-day-step="' + this._nCurrentStep + '"]');
                }


                // verify all containers
                var nContainerCount = aContainers.length;
                for (var nContainerIndex = 0; nContainerIndex < nContainerCount; nContainerIndex++)
                {
                    // register
                    var elContainer = aContainers[nContainerIndex];

                    // verify
                    if (elContainer.querySelectorAll('[data-knvb-day-option]').length > 0)
                    {
                        nWeeksFilledIn++;
                        break;
                    }
                }
            }
        }

        // 4.send
        return (nWeeksFilledIn >= nMinWeeksFilledIn);
    },

    compare: function()
    {


        // validate
        if (!this._checkInput())
        {
            KNVB.ui.popup('Bijna ..', 'Vul eerst meer weken in voordat je jouw invoer controleert.', true);
            return;
        }
        else
        {
            KNVB.ui.popup('Een ogenblik geduld a.u.b.', 'Jouw resultaten worden vergeleken met het schema van de docent.');
        }


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // register
                    var nErrorCount = response.errorCount;
                    var aMistakes = response.mistakes;

                    // show error
                    classRoot._showErrors(nErrorCount, aMistakes);

                    // show button `next`
                    if (classRoot._checkInput()) { classRoot._showNextButton(); } else { classRoot._hideNextButton(); }
                }
            }
        };

        // prepare
        request.open('get', '/course/schedule/compare/step/' + this._nCurrentStep);

        // send
        request.send();
    },

    remove: function(sConnectionId)
    {
        // 1. verify
        if (this._nCurrentStep == 2)
        {
            // 1. find
            var option = document.querySelector('[data-knvb-schedule-option-connection-id="' + sConnectionId + '"]');

            // 2. register
            var week = this._findParentWithType('data-knvb-schedule-week', option);

            // 3. read
            var nWeekId = week.getAttribute('data-knvb-schedule-week-id');

            // 4. find container
            var optionContainer = week.querySelector('[data-knvb-schedule-week-type]');

            // 5. remove
            optionContainer.removeChild(option);

            // 6. reset error
            this._resetError(week);

            // 7. update visual state
            if (optionContainer.querySelectorAll('[data-knvb-week-option]').length == 0)
            {
                optionContainer.classList.remove('filled');
            }

            // 8. store
            this._removeOptionFromWeek(nWeekId, sConnectionId);
        }
        else
        {
            // 1. find
            var option = document.querySelector('[data-knvb-schedule-option-connection-id="' + sConnectionId + '"]');

            // 2. register
            var day = this._findParentWithType('data-knvb-schedule-day', option);

            // 3. read
            var nWeekIndex = this._findParentWithType('data-knvb-schedule-week', day).getAttribute('data-knvb-schedule-week-id');
            var nDayIndex = day.getAttribute('data-knvb-schedule-day-id');

            // 3. remove
            option.parentNode.removeChild(option);

            // 4. reset error
            this._resetError(day);

            // 5. verify
            if (this._nCurrentStep != 6)
            {
                // 5. update visual state
                if (day.querySelector('[data-knvb-schedule-day-step="' + this._nCurrentStep + '"]').querySelectorAll('[data-knvb-day-option]').length === 0)
                {
                    day.classList.remove('filled');
                }
            }

            // 6. store
            this._removeOptionFromDay(nWeekIndex, nDayIndex, sConnectionId);
        }

        // update grid
        this._resizeGrid(nWeekIndex);
    },


    _createDraggableElement: function(element)
    {
        // register
        var classRoot = this;

        Draggable.create(element, {
            type: "x,y",
            throwProps: false,
            dragClickables: true,

            onDragStart: function ()
            {
                this.position = this.target.style.transform;

                // cleanup
                while (classRoot._aTooltips.length > 0)
                {
                    // register
                    var tooltip = classRoot._aTooltips.shift();

                    // eliminate
                    tooltip.tip.destroy(tooltip.popper);
                }
            },
            onDrag: function ()
            {
                // find
                var dropContainer = classRoot._getDropContainer(this);

                // check
                if (classRoot._nCurrentStep == 2)
                {
                    // find
                    var parentWeek = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer);

                    // set or reset hover state
                    var nWeekCount = classRoot._aWeeks.length;
                    for (var nWeekIndex = 0; nWeekIndex < nWeekCount; nWeekIndex++)
                    {
                        // register
                        var element = classRoot._aWeeks[nWeekIndex];

                        // verify
                        if (element === parentWeek)
                        {
                            // highlight
                            element.classList.add('hover-on-drop-container');
                        }
                        else
                        {
                            // reset
                            element.classList.remove('hover-on-drop-container');
                        }
                    }
                }
                else
                {
                    // set or reset hover state
                    var nDropContainerCount = classRoot._dropContainers.length;
                    for (var nDropContainerIndex = 0; nDropContainerIndex < nDropContainerCount; nDropContainerIndex++)
                    {
                        // register
                        var element = classRoot._dropContainers[nDropContainerIndex];

                        // verify
                        if (element === dropContainer)
                        {
                            // highlight
                            element.classList.add('hover-on-drop-container');
                        }
                        else
                        {
                            // reset
                            element.classList.remove('hover-on-drop-container');
                        }
                    }
                }

            },
            onDragEnd: function ()
            {
                // 1. register
                var droppedOption = this.target;

                // 2. register
                var dropContainer = classRoot._getDropContainer(this);

                // 3. clear
                classRoot._removeAllDropHoverStates();

                // 4. validate and reset
                if (!dropContainer) { classRoot.setupMenu(); return; }


                // ----


                // 5. read
                var itemLabelContainer = droppedOption.querySelector('.ScheduleOption-label');

                // 6. register
                var nOptionId = droppedOption.getAttribute('data-knvb-schedule-option-id');


                // ---


                // 7. check
                if (classRoot._nCurrentStep == 2)
                {
                    // a. load
                    var weekOption = document.getElementById('templates').querySelector('[data-knvb-week-option]').cloneNode(true);

                    // b. setup label container
                    var weekOptionLabel = weekOption.querySelector('[data-knvb-schedule-week-type-label]');
                    weekOptionLabel.innerText = itemLabelContainer.getAttribute('data-value');

                    // c. find the containing week
                    var week = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer);

                    // e. find actual container for the week type
                    dropContainer = week.querySelector('[data-knvb-schedule-week-type]');

                    // g. inject button into dom
                    dropContainer.appendChild(weekOption);

                    var nWeekIndex = week.getAttribute('data-knvb-schedule-week-id');

                    // h. visual update
                    dropContainer.classList.add('filled');
                    week.classList.remove('error');

                    // g. store
                    classRoot._addOptionToWeek(nWeekIndex, nOptionId, weekOption);
                }
                else if (classRoot._nCurrentStep == 5)
                {
                    // a. load and style
                    var dayOptionGroup = document.getElementById('templates').querySelector('[data-knvb-day-option-group]').cloneNode(true);
                    dayOptionGroup.classList.add('DayOptionGroup-' + droppedOption.getAttribute('data-knvb-schedule-option-group'));

                    // b. load and setup
                    var dayOption = document.getElementById('templates').querySelector('.DayOption').cloneNode(true);
                    var dayOptionLabel = dayOption.querySelector('.DayOption-label');
                    dayOptionLabel.innerText = itemLabelContainer.getAttribute('data-value');

                    // c. connect
                    dayOptionGroup.querySelector('[data-knvb-day-option-group-label]').appendChild(dayOption);

                    // d. inject button into dom
                    dropContainer.querySelector('[data-knvb-schedule-day-step="' + classRoot._nCurrentStep + '"]').appendChild(dayOptionGroup);


                    // e. read
                    var nWeekIndex = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer).getAttribute('data-knvb-schedule-week-id');
                    var nDayIndex = dropContainer.getAttribute('data-knvb-schedule-day-id');

                    // f. visual update
                    dropContainer.classList.add('filled');

                    // g. store
                    classRoot._addOptionToDay(nWeekIndex, nDayIndex, nOptionId, dayOptionGroup);


                    // h. register for resizing purposes
                    //var nWeekIndex = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer).getAttribute('data-knvb-schedule-week-id');
                }
                else
                {
                    // a. load
                    var dayOption = document.getElementById('templates').querySelector('.DayOption').cloneNode(true);

                    // b. create and setup label container
                    var dayOptionLabel = dayOption.querySelector('.DayOption-label');
                    dayOptionLabel.innerText = itemLabelContainer.getAttribute('data-value');


                    if (classRoot._nCurrentStep == 6)
                    {
                        // read
                        var elStep6Container = dropContainer.querySelector('[data-knvb-day-option-group-container]');

                        // inject
                        elStep6Container.appendChild(dayOption);

                        // find connection id
                        var nGroupConnectionId = classRoot._findParentWithType('data-knvb-day-option-group', elStep6Container).getAttribute('data-knvb-schedule-option-connection-id-container');

                        // find and register
                        var elDay = classRoot._findParentWithType('data-knvb-schedule-day-id', elStep6Container);

                        // read
                        var nWeekIndex = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer).getAttribute('data-knvb-schedule-week-id');
                        var nDayIndex = elDay.getAttribute('data-knvb-schedule-day-id');

                        // g. store
                        classRoot._addOptionToDay(nWeekIndex, nDayIndex, nOptionId, dayOption, nGroupConnectionId);

                        // 12. reset error
                        classRoot._resetError(elDay);
                    }
                    else
                    {
                        // inject
                        dropContainer.querySelector('[data-knvb-schedule-day-step="' + classRoot._nCurrentStep + '"]').appendChild(dayOption);

                        // read
                        var nWeekIndex = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer).getAttribute('data-knvb-schedule-week-id');
                        var nDayIndex = dropContainer.getAttribute('data-knvb-schedule-day-id');

                        // g. store
                        classRoot._addOptionToDay(nWeekIndex, nDayIndex, nOptionId, dayOption);
                    }

                    // e. visual update
                    dropContainer.classList.add('filled');


                    // 6. register for resizing purposes
                    var nWeekIndex = classRoot._findParentWithType('data-knvb-schedule-week', dropContainer).getAttribute('data-knvb-schedule-week-id');
                }


                // 12. reset error
                classRoot._resetError(dropContainer);

                // 13. update grid
                classRoot._resizeGrid(nWeekIndex);


                // ---


                // 14. reset menu
                classRoot.setupMenu();
            }
        });
    },

    _getDropContainer: function(instance)
    {
        if (!instance.hitTest(document.querySelector('.Base-menu')) &&
            !instance.hitTest(document.querySelector('.Schedule-header'))) {

            var nDropContainerCount = this._dropContainers.length;
            for (var nDropContainerIndex = 0; nDropContainerIndex < nDropContainerCount; nDropContainerIndex++)
            {
                if (instance.hitTest(this._dropContainers[nDropContainerIndex])) {
                    return this._dropContainers[nDropContainerIndex];
                }
            }
        }
    },

    _setupStickyDayHeader: function()
    {
        // 1. init
        var headerIsFixed;
        var nHeaderTriggerY = 0;

        // 2. read
        var elSchedule = document.getElementById('Schedule');

        // 3. get dom elements
        var elDayHeader = document.getElementById('schedule_header');
        var elMainMenu = document.getElementById('base-menu-navigation');
        var elOptionsMenu = document.getElementById('schedule_menu');

        // 4. calculate
        var offsetDayHeader = this._getCumulativeOffset(elDayHeader);
        var offsetMainMenu = this._getCumulativeOffset(elMainMenu);
        var offsetOptionsMenu = this._getCumulativeOffset(elOptionsMenu);

        // 5. determine
        if (document.body.classList.contains('showsubmenu'))
        {
            // 6. determine
            nHeaderTriggerY = offsetDayHeader.top - (offsetOptionsMenu.top + elOptionsMenu.offsetHeight);
        }
        else
        {
            // 6. determine
            nHeaderTriggerY = offsetDayHeader.top - (offsetMainMenu.top + elMainMenu.offsetHeight);
        }


        // configure
        document.addEventListener('scroll', function() {

            headerIsFixed = elSchedule.classList.contains('fix-header');

            if(window.scrollY <= nHeaderTriggerY && headerIsFixed || !headerIsFixed && window.scrollY >= nHeaderTriggerY){
                elSchedule.classList.toggle('fix-header');
                elOptionsMenu.classList.toggle('is-contracted');
            }
        });
    },

    _getCumulativeOffset: function(element) {
        var top = 0, left = 0;
        do {
            top += element.offsetTop  || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while(element);

        return {
            top: top,
            left: left
        };
    },

    _surpressDaysOffOnGrid: function()
    {
        // 1. find
        var aSurpressedDays = document.querySelectorAll('[data-knvb-day-option][data-knvb-elearning-surpressed]');

        // 2. supress all days
        var nDayCount = aSurpressedDays.length;
        for (var nDayIndex = 0; nDayIndex < nDayCount; nDayIndex++)
        {
            // find
            var elDay = this._findParentWithType('data-knvb-schedule-day', aSurpressedDays[nDayIndex]);

            // supress
            elDay.classList.add('surpressed');
        }
    },

    _styleMatchDaysOnGrid: function()
    {
        // 1. find
        var aMatchDays = document.querySelectorAll('[data-knvb-day-option][data-knvb-elearning-match]');

        // 2. supress all days
        var nDayCount = aMatchDays.length;
        for (var nDayIndex = 0; nDayIndex < nDayCount; nDayIndex++)
        {
            // find
            var elDay = this._findParentWithType('data-knvb-schedule-day', aMatchDays[nDayIndex]);

            // supress
            elDay.classList.add('match');
        }
    },

    _resizeGrid: function(nWeekId)
    {
        // 1. collect
        var aWeeks = (nWeekId) ? document.querySelectorAll('[data-knvb-schedule-week-id="' + nWeekId + '"]') : document.querySelectorAll('[data-knvb-schedule-week]');

        // 2. resize all weeks
        var nWeekCount = aWeeks.length;
        for (var nWeekIndex = 0; nWeekIndex < nWeekCount; nWeekIndex++)
        {
            // register
            var week = aWeeks[nWeekIndex];

            // search
            var weekLabel = week.querySelector('[data-knvb-schedule-week-label]');
            var weekDayContainer = week.querySelector('[data-knvb-schedule-week-day-container]');

            // clean
            weekLabel.removeAttribute('style');
            weekDayContainer.removeAttribute('style');

            // determine
            var nMaxHeight = Math.max(weekLabel.offsetWidth, weekDayContainer.offsetHeight);

            // adjust
            weekLabel.style.width = nMaxHeight + 'px';
            weekDayContainer.style.height =  nMaxHeight + 'px';
        }
    },

    _showErrors: function(nErrorCount, aMistakes)
    {
        // 1. handle
        var data = [];
        var nWeekCount = aMistakes.length;
        for (var nWeekIndex = 0; nWeekIndex < nWeekCount; nWeekIndex++)
        {
            // a. register
            var aWeekMistakes = aMistakes[nWeekIndex];

            // b. verify
            if (this._nCurrentStep == 2)
            {
                // parse mistakes
                var nMistakeCount = aWeekMistakes.length;
                for (var nMistakeIndex = 0; nMistakeIndex < nMistakeCount; nMistakeIndex++)
                {
                    // register
                    var mistake = aWeekMistakes[nMistakeIndex];

                    // register
                    var weekElement = document.querySelector('[data-knvb-schedule-week-id="' + nWeekIndex + '"]');

                    if (mistake.error)
                    {
                        // highlight
                        weekElement.classList.add('error');
                    }
                    else
                    {
                        // clear
                        weekElement.classList.remove('error');
                    }
                }
            }
            else
            {
                // parse days
                var nDayCount = aWeekMistakes.length;
                for (var nDayIndex = 0; nDayIndex < nDayCount; nDayIndex++)
                {

                    if (this._nCurrentStep == 6)
                    {
                        // register
                        var nDayId = aWeekMistakes[nDayIndex];

                        // register
                        var weekElement = document.querySelector('[data-knvb-schedule-week-id="' + nWeekIndex + '"]');
                        var dayElement = weekElement.querySelector('[data-knvb-schedule-day-id="' + nDayId + '"]');

                        if (dayElement)
                        {
                            // highlight
                            dayElement.classList.add('error');
                        }
                    }
                    else
                    {
                        // register
                        var aDayMistakes = aWeekMistakes[nDayIndex];


                        // parse mistakes
                        var nMistakeCount = aDayMistakes.length;
                        for (var nMistakeIndex = 0; nMistakeIndex < nMistakeCount; nMistakeIndex++)
                        {
                            // register
                            var mistake = aDayMistakes[nMistakeIndex];
                            console.log(mistake);
                            // register
                            var weekElement = document.querySelector('[data-knvb-schedule-week-id="' + nWeekIndex + '"]');
                            var dayElement = weekElement.querySelector('[data-knvb-schedule-day-id="' + mistake.periodId + '"]');

                            if (mistake.error)
                            {
                                // highlight
                                dayElement.classList.add('error');
                            }
                            else
                            {
                                // clear
                                dayElement.classList.remove('error');
                            }
                        }
                    }
                }
            }
        }

        // show alert
        if (nErrorCount > 0)
        {
            KNVB.ui.popup('Tussenresultaat', 'Je hebt ' + nErrorCount + ' fout' + ((nErrorCount != 1) ? 'en' : '') + ' gemaakt in deze stap. Probeer deze te verhelpen voordat je verder gaat.', true);
        }
        else
        {
            KNVB.ui.popup('Perfect', 'Je hebt geen enkele fout gemaakt. Ga door naar de volgende stap.', true);
        }

    },

    _resetError: function(day)
    {
        day.classList.remove('error');
    },

    _addOptionToDay: function(nWeekIndex, nDayIndex, nOptionId, dayOption, sGroupConnectionId)
    {
        // default
        var sGroupConnectionId = (sGroupConnectionId) ? '/group/' + sGroupConnectionId : '';


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // 1. convert
                    var response = JSON.parse(request.responseText);

                    // 2. setup
                    dayOption.setAttribute('data-knvb-schedule-option-connection-id', response.connectionId);

                    // 3. create and setup label container
                    var dayOptionRemove = dayOption.querySelector('.DayOption-remove');
                    dayOptionRemove.setAttribute('onclick', "KNVB.schedule.remove('" + response.connectionId + "')");
                    dayOption.classList.remove('hidden');
                }
            }
        };

        // prepare
        request.open('post', '/' + this._sRole + '/schedule/' + this._nCourseId + '/add/week/' + nWeekIndex + '/day/' + nDayIndex + '/step/' + this._nCurrentStep + '/option/' + nOptionId + sGroupConnectionId);

        // send
        request.send();
    },

    _removeOptionFromDay: function(nWeekIndex, nDayIndex, sConnectionId)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);
                }
            }
        };

        // prepare
        request.open('post', '/' + this._sRole + '/schedule/' + this._nCourseId + '/remove/week/' + nWeekIndex + '/day/' + nDayIndex + '/step/' + this._nCurrentStep + '/connection/' + sConnectionId);

        // send
        request.send();
    },

    _addOptionToWeek: function(nWeekId, nOptionId, weekOption)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // 1. convert
                    var response = JSON.parse(request.responseText);

                    // 2. setup
                    weekOption.setAttribute('data-knvb-schedule-option-connection-id', response.connectionId);

                    // 8c. create and setup label container
                    var weekOptionRemove = weekOption.querySelector('.WeekOption-remove');
                    weekOptionRemove.setAttribute('onclick', "KNVB.schedule.remove('" + response.connectionId + "')");
                    weekOption.classList.remove('hidden');
                }
            }
        };

        // prepare
        request.open('post', '/' + this._sRole + '/schedule/' + this._nCourseId + '/add/week/' + nWeekId + '/option/' + nOptionId);

        // send
        request.send();
    },

    _removeOptionFromWeek: function(nWeekId, sConnectionId)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);
                }
            }
        };

        // prepare
        request.open('post', '/' + this._sRole + '/schedule/' + this._nCourseId + '/remove/week/' + nWeekId + '/connection/' + sConnectionId);

        // send
        request.send();
    },

    _findParentWithType: function(sType, option)
    {
        // init
        var parent = option;

        // bubble up
        while (parent && !parent.hasAttribute(sType)) parent = parent.parentNode;

        // send
        return parent;
    },

    _removeAllDropHoverStates: function()
    {
        // find
        var aElements = document.querySelectorAll('.hover-on-drop-container');

        var nElementCount = aElements.length;
        for (var nElementIndex = 0; nElementIndex < nElementCount; nElementIndex++)
        {
            // register
            var element = aElements[nElementIndex];

            // clear
            element.classList.remove('hover-on-drop-container');
        }

    }

}
