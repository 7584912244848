/**
 * KNVB eLearning - Course
 *
 * @author Sebastian Kersten (@supertaboo)
 */

'use strict';



module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {



    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {

    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    select: function(sTrainersGroupId, bChangeTrainersGroup)
    {
        // verify with user
        if (bChangeTrainersGroup)
        {
            if (confirm('Weet je zeker dat je de reeds gestarte cursus wilt overschijven?'))
            {
                if (confirm('Weet je het echt zeker? Deze actie kan niet worden teruggedraaid!'))
                {
                    this._selectTrainersGroup(sTrainersGroupId);
                }
            }
        }
        else
        {
            this._selectTrainersGroup(sTrainersGroupId);
        }
    },

    _selectTrainersGroup: function(sTrainersGroupId)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // scroll to top
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                    // show course options
                    window.open('/', '_self');
                }
                else
                {
                    classRoot._slideContainer.innerHTML = 'Er heeft zich een probleem voorgedaan met het selecteren van een cursus. Neem a.u.b. contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.';
                }
            }
        };

        // prepare
        request.open('get', '/trainersgroup/select/' + sTrainersGroupId);

        // send
        request.send();
    },

    gotoPage: function(sURL, bNotifyUserOfLongWait)
    {
        // notify
        if (bNotifyUserOfLongWait) KNVB.ui.popup('Een ogenblik geduld a.u.b.', 'Het schema wordt geladen.');

        // navigate
        window.open(sURL, '_self');
    },

    selectMultipleChoiceAnswer: function(sHashedExamId, nMultipleChoiceQuestionId, elSelectedOption)
    {
        // 1. change selection visually
        for (var nOptionIndex = 1; nOptionIndex <= 4; nOptionIndex++)
        {
            // register
            var elOption = document.getElementById('multipleChoiceQuestion' + nOptionIndex);

            if (elOption === elSelectedOption)
            {
                elOption.parentNode.classList.add('MultipleChoiceSlide-answer--selected');
            }
            else
            {
                elOption.parentNode.classList.remove('MultipleChoiceSlide-answer--selected');
            }
        }



        // --- register answer


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);
                }
                else
                {
                    alert('Er heeft zich een probleem voorgedaan met het opslaan van je antwoord. Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // prepare
        request.open('get', '/exam/' + sHashedExamId + '/submitanswer/' + nMultipleChoiceQuestionId + '/' + elSelectedOption.value);

        // send
        request.send();
    },

    submitMultipleChoice: function(sHashedExamId)
    {
        // 1. init
        var request = new XMLHttpRequest();

        // 2. setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload
                    location.reload();
                }
                else
                {
                    alert('Er heeft zich een probleem voorgedaan met het inleveren van het multiple choice gedeelte. Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // 3. prepare
        request.open('get', '/exam/' + sHashedExamId + '/submitsection/multiplechoice');

        // 4. send
        request.send();
    },

    submitExam: function(sHashedExamId)
    {
        // 1. init
        var request = new XMLHttpRequest();

        // 2. setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4)
            {
                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload
                    location.reload();
                }
                else
                {
                    alert('Er heeft zich een probleem voorgedaan met het inlevereb van het VCT planning gedeelte. Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // 3. prepare
        request.open('get', '/exam/' + sHashedExamId + '/submitsection/vctplanning');

        // 4. send
        request.send();
    },

    reopenMultipleChoice: function(nTestResultId)
    {
        if (confirm('Weet je zeker dat je het multiple choice gedeelte voor deze cursist wilt heropenen?'))
        {
            // 1. init
            var request = new XMLHttpRequest();

            // 2. setup
            request.onreadystatechange = function()
            {
                if(request.readyState === 4)
                {
                    if(request.status === 200)
                    {
                        // convert
                        var response = JSON.parse(request.responseText);

                        // reload
                        location.reload();
                    }
                    else
                    {
                        alert('Er heeft zich een probleem voorgedaan met het heropenen van het multiple choice gedeelte. Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                    }
                }
            };

            // 3. prepare
            request.open('get', '/exam/' + nTestResultId + '/reopen/multiplechoice');

            // 4. send
            request.send();
        }
    },

    reopenExam: function(nTestResultId)
    {
        if (confirm('Weet je zeker dat je het VCT planningsgedeelte voor deze cursist wilt heropenen?'))
        {
            // 1. init
            var request = new XMLHttpRequest();

            // 2. setup
            request.onreadystatechange = function()
            {
                if(request.readyState === 4)
                {
                    if(request.status === 200)
                    {
                        // convert
                        var response = JSON.parse(request.responseText);

                        // reload
                        location.reload();
                    }
                    else
                    {
                        alert('Er heeft zich een probleem voorgedaan met het heropenen van het VCT planningsgedeelte. Probeer het a.u.b. nogmaals of neem contact op met de docent zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                    }
                }
            };

            // 3. prepare
            request.open('get', '/exam/' + nTestResultId + '/reopen/vctplanning');

            // 4. send
            request.send();
        }
    },

}
