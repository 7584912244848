/**
 * KNVB eLearning - UI
 *
 * @author Sebastian Kersten (@supertaboo)
 */

'use strict';


module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {


    // interface
    _elLayerApplication: null,
    _elLayerOverlay: null,
    _elLayerPopup: null,
    _elPopup: null,
    _elTitle: null,
    _elDescription: null,
    _elButton: null,


    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {
        // 1. add core css classes
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.layer_application { ' +
            'position: absolute;\n' +
            'top: 0;\n' +
            'right: 0;\n' +
            'bottom: 0;\n' +
            'left: 0;\n' +
            'overflow: auto; ' +
        '}';
        document.getElementsByTagName('head')[0].appendChild(style);


        // register
        this._elLayerApplication = document.body;
        this._elLayerOverlay = document.getElementById('layer_overlay');
        this._elLayerPopup = document.getElementById('layer_popup');
        this._elPopup = document.getElementById('popup');
        this._elTitle = popup.querySelector('[data-knvb-popup-title]');
        this._elDescription = popup.querySelector('[data-knvb-popup-description]');
        this._elButton = popup.querySelector('[data-knvb-popup-button]');
    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    popup: function(sTitle, sDescription, bShowButton)
    {
        // lock background from scrolling
        this._elLayerApplication.classList.add("layer_application");

        // setup
        this._elTitle.innerText = sTitle;
        this._elDescription.innerText = sDescription;
        if (bShowButton) { this._elButton.classList.remove('hidden'); } else { this._elButton.classList.add('hidden'); }

        // show
        this._elLayerOverlay.classList.remove('hidden');
        this._elLayerPopup.classList.remove('hidden');
    },

    closePopup: function()
    {
        // hide
        this._elLayerOverlay.classList.add('hidden');
        this._elLayerPopup.classList.add('hidden');

        // unlock background for scrolling
        this._elLayerApplication.classList.remove('layer_application');
    }
    
}
