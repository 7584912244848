/**
 * KNVB eLearning - Student
 *
 * @author Sebastian Kersten (@supertaboo)
 */

'use strict';


module.exports = function() {

    // start
    this.__construct();
};

module.exports.prototype = {



    // ----------------------------------------------------------------------------
    // --- Constructor ------------------------------------------------------------
    // ----------------------------------------------------------------------------


    /**
     * Constructor
     */
    __construct: function()
    {

    },



    // ----------------------------------------------------------------------------
    // --- Public methods ---------------------------------------------------------
    // ----------------------------------------------------------------------------


    markAsActive: function(nStudentId)
    {
        // forward
        this._changeStatus('/student/' + nStudentId + '/mark/active');
    },

    markAsChecked: function(nStudentId)
    {
        // forward
        this._changeStatus('/student/' + nStudentId + '/mark/checked');
    },

    markAsArchived: function(nStudentId)
    {
        // forward
        this._changeStatus('/student/' + nStudentId + '/mark/archived');
    },


    _changeStatus: function(sURL)
    {
        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload page
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    location.reload();
                }
                else
                {
                    alert('Er is een fout opgetreden bij het wijzigen van de status van de cursist. Neem a.u.b. contact op met Momkai zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // prepare
        request.open('get', sURL);

        // send
        request.send();
    },

    reset: function(nStudentId)
    {
        // verify
        if (confirm('Weet je zeker dat je deze cursist wilt resetten?\n\nALLE data van deze cursist zal verloren gaan!'))
        {
            if (!confirm('Weet je absoluut zeker?\n\nDeze actie kan niet worden teruggedraaid!')) return;
        }
        else
        {
            return;
        }


        // ---


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload page
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    location.reload();
                }
                else
                {
                    alert('Er is een fout opgetreden bij het resetten van de cursist. Neem a.u.b. contact op met Momkai zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // prepare
        request.open('get', '/student/' + nStudentId + '/reset');

        // send
        request.send();
    },


    resetStudentPracticeResults: function(nStudentId)
    {
        // verify
        if (confirm('Weet je zeker dat je de oefentoets van deze cursist wilt resetten?'))
        {
            if (!confirm('Weet je absoluut zeker?\n\nDeze actie kan niet worden teruggedraaid!')) return;
        }
        else
        {
            return;
        }


        // ---


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload page
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    location.reload();
                }
                else
                {
                    alert('Er is een fout opgetreden bij het resetten van de cursist. Neem a.u.b. contact op met Momkai zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // prepare
        request.open('get', '/student/' + nStudentId + '/resetpracticeresults');

        // send
        request.send();
    },

    resetStudentTestResult: function(nStudentId, nTestResultId, bSkipMultipleChoice)
    {
        // verify
        if (confirm('Weet je zeker dat je de toets van deze cursist wilt resetten?'))
        {
            if (!confirm('Weet je absoluut zeker?\n\nDeze actie kan niet worden teruggedraaid!')) return;
        }
        else
        {
            return;
        }


        // ---


        // register
        var classRoot = this;

        // init
        var request = new XMLHttpRequest();

        // setup
        request.onreadystatechange = function()
        {
            if(request.readyState === 4) {

                if(request.status === 200)
                {
                    // convert
                    var response = JSON.parse(request.responseText);

                    // reload page
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    location.reload();
                }
                else
                {
                    alert('Er is een fout opgetreden bij het resetten van de toets van deze cursist. Neem a.u.b. contact op met Momkai zodat we dit zo spoedig mogelijk voor je kunnen oplossen.');
                }
            }
        };

        // prepare
        if (bSkipMultipleChoice === true)
        {
            request.open('get', '/student/' + nStudentId + '/resettestresult' + '/' + nTestResultId + '/scheduleonly');
        }
        else
        {
            request.open('get', '/student/' + nStudentId + '/resettestresult' + '/' + nTestResultId + '/full');
        }


        // send
        request.send();
    }
    
}
