// Mimoto classes
var KNVB = require('./app/KNVB');

// init
window.KNVB = new KNVB();

// connect
document.addEventListener('DOMContentLoaded', function () {

    window.KNVB.startup();

}, true);
